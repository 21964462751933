.root {

    flex-direction: column;
    position: relative;
    display: none;

    @media (min-width: 1000px) {
        display: flex;
    }
}

.searchOptions {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.searchResultSummary {
    composes: textSmall from global;

    margin-top: 10px;
    margin-bottom: 11px;
    margin-left: 0px;
    margin-right: 8px;
}

.loadingResults {
    composes: textSmall from global;

    /* Cover parent element's space*/
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* Layout */
    padding: 9px 0 0 0;
    margin: 0;
}

.resultsFound {
    white-space: nowrap;
}

.sortyByWrapper {
    display: inline-flex;
    margin-left: auto;
}

.sortyBy {
    composes: marketplaceSmallFontStyles from global;
    font-weight: var(--fontWeightRegular);
    padding: 8px 8px 8px 0;
    white-space: nowrap;
}

.filtersRow {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    width: 100%;
}

@media (max-width: 768px) {
    .filtersRow {
        display: none;
    }
}